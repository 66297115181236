<template>
  <div id="vod-view">
    <el-form label-width="80px">
      <el-form-item label="预览">
        <div class="video-container">
          <div class="top-player">
            <video id="player-container-id" preload="auto" playsinline webkit-playsinline></video>
          </div>
        </div>
      </el-form-item>
    </el-form>

    <!-- <el-button size="small" @click="onFull()">full screen</el-button> -->
  </div>
</template>

<script>
//import { alertMessage } from "@/util/alertMessageUtil.js";
import { dogeBaseApi } from "@/api/dogeBaseApi";
import msgLogger from "@/util/msgLogger";
export default {
  components: {},
  data() {
    return {
      vcode: "",
      vodParam: {},
      urlWithKey: "",
      player: null,
    };
  },
  mounted() {
    this.vcode = this.$route.query.vcode;
    if (!this.vcode) {
      return;
    }
    this.onGetVodUrl(this.vcode);
  },
  destroyed() {
    if (this.player) {
      this.player.dispose();
    }
  },

  methods: {
    onGetVodUrl(vcode) {
      let _this = this;
      dogeBaseApi.getVodUrl(vcode).then((ret) => {
        console.log(ret);
        let { code, data } = ret;
        if (code == 0 && data) {
          let player = new this.$TcPlayer("player-container-id", { playbackRates: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2] }); // player-container-id 为播放器容器 ID，必须与 html 中一致
          _this.player = player;
          player.src(data.stream[0].url); // url 播放地址
        } else {
          msgLogger.error("视频没上传成功或者没转码，请稍等");
        }
      });
    },
  },
  watch: {},
};
</script>

<style>
.top-class {
  position: absolute;
  z-index: 10000;
  top: 0px;
  left: 0px;
}
</style>

<style lang="less" scoped>
#vod-view {
  margin: 20px;
  width: 60%;
  min-width: 600px;
  .video {
    width: 100%;
  }
  .video-container {
    width: 800px;
    height: 600px;
    display: flex;
    flex-direction: column;
    .top-player {
      #player-container-id {
        width: 100%;
        height: 600px;
      }
    }
  }
}
</style>
